import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    LinearProgress,
    Typography,
    Grid,
    Card,
    Box,
    useTheme,
    Chip,
    Stack,
    Divider,
    IconButton,
    Tooltip,
    Paper,
    Fade
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from "notistack";
import { Company } from "../../types/Company";
import { useApi } from "../../context/ApiProvider";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import UpdateIcon from '@mui/icons-material/Update';
import UpsertCompanyModal from './UpsertCompanyModal';

type RouteParams = {
    [key: number]: string;
};

const CompanyOverview: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const api = useApi();
    const { id } = useParams<RouteParams>() as { id: number };
    const [isLoading, setLoading] = useState(true);
    const [company, setCompany] = useState<Company | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setLoading(true);
        api?.get(`/companies/${id}`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch company data!');
                }
                setCompany(response.body);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch company data!', { variant: 'error' });
                setCompany(null);
            })
            .finally(() => setLoading(false));
    }, [id, api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this company?')) {
            api?.delete(`/companies/${id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete company');
                    }
                    enqueueSnackbar('Company deleted successfully!', { variant: 'success' });
                    navigate('/companies');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete company!', { variant: 'error' });
                });
        }
    };

    const InfoRow = ({ icon, label, value }: { icon: React.ReactNode, label: string, value: React.ReactNode }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5 }}>
            {icon}
            <Typography variant="body2" color="text.secondary" sx={{ minWidth: 120 }}>
                {label}
            </Typography>
            <Typography variant="body2" color="text.primary" sx={{ flex: 1 }}>
                {value}
            </Typography>
        </Box>
    );

    return (
        <Fade in timeout={800}>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
                            Company Details
                        </Typography>
                        {company?.is_active && (
                            <Chip
                                label="Active"
                                color="success"
                                size="small"
                                sx={{ fontWeight: 500 }}
                            />
                        )}
                    </Box>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Edit Company">
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => setIsModalOpen(true)}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Edit
                            </Button>
                        </Tooltip>
                        <Tooltip title="Delete Company">
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={handleDelete}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>

                {isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : company && (
                    <Grid item xs={12}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 3,
                                boxShadow: theme.shadows[2],
                            }}
                        >
                            <Box sx={{ p: 3 }}>
                                <Typography variant="h6" gutterBottom fontWeight={600}>
                                    Company Information
                                </Typography>

                                <Box sx={{ mt: 2 }}>
                                    <InfoRow
                                        icon={<BusinessIcon color="primary" />}
                                        label="Company Name"
                                        value={company.name}
                                    />
                                    <Divider />
                                    <InfoRow
                                        icon={<CalendarTodayIcon color="action" />}
                                        label="Created At"
                                        value={new Date(company.created_at!).toLocaleString()}
                                    />
                                    <Divider />
                                    <InfoRow
                                        icon={<UpdateIcon color="action" />}
                                        label="Last Updated"
                                        value={new Date(company.updated_at!).toLocaleString()}
                                    />
                                </Box>

                                <Box sx={{ mt: 3 }}>
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            p: 2,
                                            borderRadius: 2,
                                            borderColor: company.is_active ? 'success.main' : 'divider',
                                            bgcolor: company.is_active ? 'success.lighter' : 'background.default',
                                        }}
                                    >
                                        <Typography variant="subtitle2" color={company.is_active ? 'success.main' : 'text.secondary'}>
                                            Company Status
                                        </Typography>
                                        <Typography variant="body2" color={company.is_active ? 'success.dark' : 'text.disabled'}>
                                            {company.is_active ? 'Active' : 'Inactive'}
                                        </Typography>
                                    </Paper>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                )}

                <UpsertCompanyModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSuccess={fetchData}
                    companyId={id.toString()}
                />
            </Grid>
        </Fade>
    );
};

export default CompanyOverview;