import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    Typography,
    Grid,
    Stack,
    Card,
    Box,
    IconButton,
    Tooltip,
    useTheme,
    TablePagination,
    Fade
} from '@mui/material';
import { enqueueSnackbar } from "notistack";
import { Wallet } from "../../types/Wallet";
import CompanyLink from "../Company/CompanyLink";
import WalletLink from "./WalletLink";
import { useApi } from "../../context/ApiProvider";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import UpsertWalletModal from './UpsertWalletModal';

const WalletComponent: React.FC = () => {
    const theme = useTheme();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedWalletId, setSelectedWalletId] = useState<string | undefined>();
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true);
        api?.get(`/wallets`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch wallets data!');
                }
                setWallets(response.body.results);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch wallets data!', { variant: 'error' });
                setWallets([]);
            })
            .finally(() => setIsLoading(false));
    }, [api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = (wallet: Wallet) => {
        if (window.confirm('Are you sure you want to delete this wallet?')) {
            api?.delete(`/wallets/${wallet.id}`)
                .then(() => {
                    enqueueSnackbar('Wallet deleted successfully!', { variant: 'success' });
                    fetchData();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete wallet!', { variant: 'error' });
                });
        }
    };

    const handleEdit = (walletId: number) => {
        setSelectedWalletId(walletId.toString());
        setIsModalOpen(true);
    };

    const handleAdd = () => {
        setSelectedWalletId(undefined);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedWalletId(undefined);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Fade in timeout={800}>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
                        Wallets
                    </Typography>
                    <Button
                        onClick={handleAdd}
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            background: theme.palette.primary.main,
                            '&:hover': {
                                background: theme.palette.primary.dark,
                            }
                        }}
                    >
                        Add Wallet
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 3,
                            boxShadow: theme.shadows[2],
                            overflow: 'hidden'
                        }}
                    >
                        {isLoading ? (
                            <LinearProgress sx={{ height: 2 }} />
                        ) : (
                            <Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Wallet</TableCell>
                                                <TableCell>Company</TableCell>
                                                <TableCell>Created At</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {wallets
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((wallet: Wallet) => (
                                                    <TableRow key={wallet.id} hover>
                                                        <TableCell>
                                                            <Stack direction="row" spacing={2} alignItems="center">
                                                                <AccountBalanceWalletIcon color="primary" />
                                                                <WalletLink wallet={wallet} />
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell>
                                                            <CompanyLink company={wallet.company} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Date(wallet.created_at!).toLocaleDateString()}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                                                <Tooltip title="Edit Wallet">
                                                                    <IconButton
                                                                        onClick={() => handleEdit(wallet.id)}
                                                                        size="small"
                                                                        sx={{
                                                                            color: 'warning.main',
                                                                            '&:hover': { backgroundColor: 'warning.lighter' }
                                                                        }}
                                                                    >
                                                                        <EditIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete Wallet">
                                                                    <IconButton
                                                                        onClick={() => handleDelete(wallet)}
                                                                        size="small"
                                                                        sx={{
                                                                            color: 'error.main',
                                                                            '&:hover': { backgroundColor: 'error.lighter' }
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={wallets.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                        )}
                    </Card>
                </Grid>

                <UpsertWalletModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    onSuccess={fetchData}
                    walletId={selectedWalletId}
                />
            </Grid>
        </Fade>
    );
};

export default WalletComponent;