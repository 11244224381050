import React from 'react';
import { Typography, Box } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const LoginHeader: React.FC = () => {
    return (
        <Box sx={{ mb: 3, textAlign: 'center' }}>
            <Box
                sx={{
                    backgroundColor: 'primary.main',
                    width: 45,
                    height: 45,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto 16px',
                }}
            >
                <LockOutlinedIcon sx={{ color: 'white' }} />
            </Box>
            <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{
                    fontWeight: 700,
                    color: 'text.primary',
                }}
            >
                Welcome Back
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    color: 'text.secondary',
                    mb: 2,
                }}
            >
                Please sign in to continue
            </Typography>
        </Box>
    );
};

export default LoginHeader;