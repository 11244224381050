import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    Typography,
    Grid,
    Stack,
    Card,
    Box,
    IconButton,
    Tooltip,
    useTheme,
    TablePagination,
    Chip,
    Fade
} from '@mui/material';
import { enqueueSnackbar } from "notistack";
import { Company } from "../../types/Company";
import CompanyLink from "./CompanyLink";
import { useApi } from "../../context/ApiProvider";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BusinessIcon from '@mui/icons-material/Business';
import UpsertCompanyModal from './UpsertCompanyModal';

const CompaniesComponent: React.FC = () => {
    const theme = useTheme();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>();
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true);
        api?.get(`/companies`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch companies data!');
                }
                setCompanies(response.body.results ?? []);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch companies data!', { variant: 'error' });
                setCompanies([]);
            })
            .finally(() => setIsLoading(false));
    }, [api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = (company: Company) => () => {
        if (window.confirm('Are you sure you want to delete this company?')) {
            api?.delete(`/companies/${company.id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete company');
                    }
                    enqueueSnackbar('Company deleted successfully!', { variant: 'success' });
                    fetchData();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete company!', { variant: 'error' });
                });
        }
    };

    const handleEdit = (companyId: number) => {
        setSelectedCompanyId(companyId.toString());
        setIsModalOpen(true);
    };

    const handleAdd = () => {
        setSelectedCompanyId(undefined);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCompanyId(undefined);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Fade in timeout={800}>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
                        Companies
                    </Typography>
                    <Button
                        onClick={handleAdd}
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            background: theme.palette.primary.main,
                            '&:hover': {
                                background: theme.palette.primary.dark,
                            }
                        }}
                    >
                        Add Company
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 3,
                            boxShadow: theme.shadows[2],
                            overflow: 'hidden'
                        }}
                    >
                        {isLoading ? (
                            <LinearProgress sx={{ height: 2 }} />
                        ) : (
                            <Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Created At</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {companies
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((company: Company) => (
                                                    <TableRow key={company.id} hover>
                                                        <TableCell>
                                                            <Stack direction="row" spacing={2} alignItems="center">
                                                                <BusinessIcon color="primary" />
                                                                <CompanyLink company={company} />
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Chip
                                                                label={company.is_active ? 'Active' : 'Inactive'}
                                                                color={company.is_active ? 'success' : 'default'}
                                                                size="small"
                                                                sx={{ fontWeight: 500 }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Date(company.created_at!).toLocaleDateString()}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                                                <Tooltip title="Edit Company">
                                                                    <IconButton
                                                                        onClick={() => handleEdit(company.id)}
                                                                        size="small"
                                                                        sx={{
                                                                            color: 'warning.main',
                                                                            '&:hover': { backgroundColor: 'warning.lighter' }
                                                                        }}
                                                                    >
                                                                        <EditIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete Company">
                                                                    <IconButton
                                                                        onClick={handleDelete(company)}
                                                                        size="small"
                                                                        sx={{
                                                                            color: 'error.main',
                                                                            '&:hover': { backgroundColor: 'error.lighter' }
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={companies.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                        )}
                    </Card>
                </Grid>

                <UpsertCompanyModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    onSuccess={fetchData}
                    companyId={selectedCompanyId}
                />
            </Grid>
        </Fade>
    );
};

export default CompaniesComponent;