import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    List,
    Toolbar,
    Typography,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    Divider,
    Avatar,
    useTheme,
    IconButton,
    useMediaQuery,
    Tooltip
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { navbarItems } from './Navbar';
import DistributeChainComponent from './components/distributeChains/DistributeChainComponent';
import DashboardComponent from './components/DashboardComponent';
import DistributeChainOverview from './components/distributeChains/DistributeChainOverview';
import UpsertDistributeChain from './components/distributeChains/UpsertDistributeChain';
import UpsertWallet from "./components/Wallet/UpsertWallet";
import WalletOverview from "./components/Wallet/WalletOverview";
import WalletComponent from "./components/Wallet/WalletComponent";
import UpsertTask from "./components/Tasks/UpsertTask";
import NotificationChannelsComponent from "./components/notificationChannels/NotificationChannelsComponent";
import LoginComponent from "./components/Security/LoginComponent";
import { LogoutComponent } from "./components/Security/LogoutComponent";
import CompaniesComponent from "./components/Company/CompaniesComponent";
import CompanyOverview from "./components/Company/CompanyOverview";
import UpsertCompany from "./components/Company/UpsertCompany";
import UserComponent from "./components/User/UserComponent";
import UserOverview from "./components/User/UserOverview";
import UpsertUser from "./components/User/UpsertUser";
import { useUserStore } from "./stores/useUserStore";
import { useApi } from "./context/ApiProvider";

const DRAWER_WIDTH = 280;

function AppContent() {
    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const api = useApi();
    const { user, setUser, securityTokens, setApiSecurityTokens } = useUserStore();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const fetchMe = useCallback(() => {
        if (!user) {
            api?.get('/users/me')
                .then((response) => {
                    if (!response.ok || !response.body) {
                        throw new Error('Failed to fetch user!')
                    }
                    setUser(response.body);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setUser(undefined);
                    setApiSecurityTokens(null);
                });
        }
    }, [user, setUser, securityTokens]);

    useEffect(() => {
        if (securityTokens) {
            fetchMe();
        } else {
            setUser(undefined);
            setApiSecurityTokens(null);
        }
    }, [fetchMe, securityTokens]);

    const drawer = (
        <>
            <Box sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                borderBottom: '1px solid',
                borderColor: 'divider'
            }}>
                <Avatar
                    sx={{
                        bgcolor: theme.palette.primary.main,
                        width: 40,
                        height: 40,
                        fontWeight: 600
                    }}
                >
                    {user?.username?.charAt(0).toUpperCase() || 'U'}
                </Avatar>
                <Box>
                    <Typography variant="subtitle1" fontWeight={600}>
                        {user?.username}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {user?.is_super_admin ? 'Administrator' : 'User'}
                    </Typography>
                </Box>
            </Box>

            <List component="nav" sx={{ px: 2, py: 1, flexGrow: 1 }}>
                {navbarItems.map((item) => (
                    user && item.security(user) && (
                        <ListItem key={item.name} disablePadding>
                            <ListItemButton
                                component={Link}
                                to={item.path}
                                selected={location.pathname === item.path}
                            >
                                <ListItemIcon>
                                    <item.icon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.name}
                                    primaryTypographyProps={{
                                        fontSize: '0.9375rem',
                                        fontWeight: location.pathname === item.path ? 600 : 400
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    )
                ))}
            </List>
        </>
    );

    if (!securityTokens) {
        return <LoginComponent />;
    }

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
                    ml: { md: `${DRAWER_WIDTH}px` }
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <IconButton
                        color="primary"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="primary" fontWeight="600">
                        TED Validator Tools
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box
                component="nav"
                sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
            >
                {isMobile ? (
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{ keepMounted: true }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': {
                                width: DRAWER_WIDTH,
                                boxSizing: 'border-box'
                            },
                        }}
                    >
                        {drawer}
                    </Drawer>
                ) : (
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            '& .MuiDrawer-paper': {
                                width: DRAWER_WIDTH,
                                boxSizing: 'border-box'
                            },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                )}
            </Box>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
                    minHeight: '100vh',
                    backgroundColor: 'background.default'
                }}
            >
                <Toolbar />
                <Box
                    sx={{
                        borderRadius: theme.shape.borderRadius,
                        bgcolor: 'background.paper',
                        p: 3,
                        boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Routes>
                        <Route path="/" element={<DashboardComponent />} />
                        <Route path="/distribute-chains" element={<DistributeChainComponent />} />
                        <Route path="/distribute-chains/:id" element={<DistributeChainOverview />} />
                        <Route path="/distribute-chains/:id/edit" element={<UpsertDistributeChain />} />
                        <Route path="/distribute-chains/add" element={<UpsertDistributeChain />} />
                        <Route path="/distribute-chains/:id/task/add" element={<UpsertTask />} />
                        <Route path="/distribute-chains/:id/task/:task_id/edit" element={<UpsertTask />} />
                        <Route path="/wallets" element={<WalletComponent />} />
                        <Route path="/wallets/:id" element={<WalletOverview />} />
                        <Route path="/wallets/:id/edit" element={<UpsertWallet />} />
                        <Route path="/wallets/add" element={<UpsertWallet />} />
                        <Route path="/notification-channels" element={<NotificationChannelsComponent />} />
                        <Route path="/companies" element={<CompaniesComponent />} />
                        <Route path="/companies/:id" element={<CompanyOverview />} />
                        <Route path="/companies/:id/edit" element={<UpsertCompany />} />
                        <Route path="/companies/add" element={<UpsertCompany />} />
                        <Route path="/users" element={<UserComponent />} />
                        <Route path="/users/:id" element={<UserOverview />} />
                        <Route path="/users/:id/edit" element={<UpsertUser />} />
                        <Route path="/users/add" element={<UpsertUser />} />
                        <Route path="/logout" element={<LogoutComponent />} />
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
}

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;