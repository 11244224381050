import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    LinearProgress,
    Typography,
    Grid,
    Card,
    Box,
    useTheme,
    Chip,
    Stack,
    Divider,
    IconButton,
    Tooltip,
    Paper,
    Fade
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from "notistack";
import { User } from "../../types/User";
import CompanyLink from "../Company/CompanyLink";
import { useApi } from "../../context/ApiProvider";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import UpdateIcon from '@mui/icons-material/Update';

type RouteParams = {
    [key: number]: string;
};

const UserOverview: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const api = useApi();
    const { id } = useParams<RouteParams>() as { id: number };
    const [isLoading, setLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setLoading(true);
        api?.get(`/users/${id}`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch user data!');
                }
                setUser(response.body);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch user data!', { variant: 'error' });
                setUser(null);
            })
            .finally(() => setLoading(false));
    }, [id, api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            api?.delete(`/users/${id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete user');
                    }
                    enqueueSnackbar('User deleted successfully!', { variant: 'success' });
                    navigate('/users');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete user!', { variant: 'error' });
                });
        }
    };

    const InfoRow = ({ icon, label, value }: { icon: React.ReactNode, label: string, value: React.ReactNode }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5 }}>
            {icon}
            <Typography variant="body2" color="text.secondary" sx={{ minWidth: 120 }}>
                {label}
            </Typography>
            <Typography variant="body2" color="text.primary" sx={{ flex: 1 }}>
                {value}
            </Typography>
        </Box>
    );

    return (
        <Fade in timeout={800}>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
                            User Details
                        </Typography>
                        {user?.is_active && (
                            <Chip
                                label="Active"
                                color="success"
                                size="small"
                                sx={{ fontWeight: 500 }}
                            />
                        )}
                    </Box>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Edit User">
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => navigate(`/users/${id}/edit`)}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Edit
                            </Button>
                        </Tooltip>
                        <Tooltip title="Delete User">
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={handleDelete}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>

                {isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : user && (
                    <>
                        <Grid item xs={12} md={8}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 3,
                                    boxShadow: theme.shadows[2],
                                    height: '100%',
                                }}
                            >
                                <Box sx={{ p: 3 }}>
                                    <Typography variant="h6" gutterBottom fontWeight={600}>
                                        User Information
                                    </Typography>

                                    <Box sx={{ mt: 2 }}>
                                        <InfoRow
                                            icon={user.is_super_admin ? (
                                                <AdminPanelSettingsIcon color="primary" />
                                            ) : (
                                                <PersonIcon color="action" />
                                            )}
                                            label="Username"
                                            value={user.username}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<BusinessIcon color="action" />}
                                            label="Company"
                                            value={<CompanyLink company={user.company} />}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<CalendarTodayIcon color="action" />}
                                            label="Created At"
                                            value={new Date(user.created_at!).toLocaleString()}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<UpdateIcon color="action" />}
                                            label="Last Updated"
                                            value={new Date(user.updated_at!).toLocaleString()}
                                        />
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 3,
                                    boxShadow: theme.shadows[2],
                                    height: '100%',
                                }}
                            >
                                <Box sx={{ p: 3 }}>
                                    <Typography variant="h6" gutterBottom fontWeight={600}>
                                        Permissions
                                    </Typography>

                                    <Stack spacing={2} sx={{ mt: 2 }}>
                                        <Paper
                                            variant="outlined"
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                                borderColor: user.is_active ? 'success.main' : 'divider',
                                                bgcolor: user.is_active ? 'success.lighter' : 'background.default',
                                            }}
                                        >
                                            <Typography variant="subtitle2" color={user.is_active ? 'success.main' : 'text.secondary'}>
                                                Account Status
                                            </Typography>
                                            <Typography variant="body2" color={user.is_active ? 'success.dark' : 'text.disabled'}>
                                                {user.is_active ? 'Active' : 'Inactive'}
                                            </Typography>
                                        </Paper>

                                        <Paper
                                            variant="outlined"
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                                borderColor: user.is_super_admin ? 'primary.main' : 'divider',
                                                bgcolor: user.is_super_admin ? 'primary.lighter' : 'background.default',
                                            }}
                                        >
                                            <Typography variant="subtitle2" color={user.is_super_admin ? 'primary.main' : 'text.secondary'}>
                                                Admin Level
                                            </Typography>
                                            <Typography variant="body2" color={user.is_super_admin ? 'primary.dark' : 'text.disabled'}>
                                                {user.is_super_admin ? 'Super Administrator' : (user.is_admin ? 'Administrator' : 'Regular User')}
                                            </Typography>
                                        </Paper>
                                    </Stack>
                                </Box>
                            </Card>
                        </Grid>
                    </>
                )}
            </Grid>
        </Fade>
    );
};

export default UserOverview;