import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    Typography,
    Grid,
    Stack,
    Card,
    Box,
    IconButton,
    Tooltip,
    useTheme,
    TablePagination,
    Chip,
    Fade
} from '@mui/material';
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { DistributeChain } from "../../types/DistributeChain";
import CompanyLink from "../Company/CompanyLink";
import { useApi } from "../../context/ApiProvider";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import WalletLink from '../Wallet/WalletLink';

const DistributeChainComponent: React.FC = () => {
    const theme = useTheme();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [chains, setChains] = useState<DistributeChain[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true);
        api?.get('/distribute-chains')
            .then((response) => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch distribute chain data!');
                }
                setChains(response.body.results);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch distribute chain data!', { variant: 'error' });
                setChains([]);
            })
            .finally(() => setIsLoading(false));
    }, [api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = (chain: DistributeChain) => {
        if (window.confirm('Are you sure you want to delete this distribute chain?')) {
            api?.delete(`/distribute-chains/${chain.id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete distribute chain');
                    }
                    enqueueSnackbar('Distribute chain deleted successfully!', { variant: 'success' });
                    fetchData();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete distribute chain!', { variant: 'error' });
                });
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getStatusColor = (chain: DistributeChain) => {
        if (!chain.is_active) return 'default';
        if (chain.last_error) return 'error';
        if (chain.last_claimed_at) return 'success';
        return 'warning';
    };

    const getStatusText = (chain: DistributeChain) => {
        if (!chain.is_active) return 'Inactive';
        if (chain.last_error) return 'Error';
        if (chain.last_claimed_at) return 'Active';
        return 'Pending';
    };

    return (
        <Fade in timeout={800}>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
                        Distribute Chains
                    </Typography>
                    <Button
                        component={Link}
                        to="/distribute-chains/add"
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            background: theme.palette.primary.main,
                            '&:hover': {
                                background: theme.palette.primary.dark,
                            }
                        }}
                    >
                        Add Chain
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 3,
                            boxShadow: theme.shadows[2],
                            overflow: 'hidden'
                        }}
                    >
                        {isLoading ? (
                            <LinearProgress sx={{ height: 2 }} />
                        ) : (
                            <Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Chain</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Wallet</TableCell>
                                                <TableCell>Company</TableCell>
                                                <TableCell>Last Claimed</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {chains
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((chain) => (
                                                    <TableRow key={chain.id} hover>
                                                        <TableCell>
                                                            <Stack direction="row" spacing={2} alignItems="center">
                                                                <SettingsIcon color="primary" />
                                                                <Box>
                                                                    <Typography variant="body2" fontWeight={500}>
                                                                        {chain.name}
                                                                    </Typography>
                                                                    <Typography variant="caption" color="text.secondary">
                                                                        {chain.id} - {chain.chain}
                                                                    </Typography>
                                                                </Box>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Chip
                                                                label={getStatusText(chain)}
                                                                color={getStatusColor(chain)}
                                                                size="small"
                                                                sx={{ fontWeight: 500 }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {chain.wallet && <WalletLink wallet={chain.wallet} />}
                                                        </TableCell>
                                                        <TableCell>
                                                            <CompanyLink company={chain.company} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {chain.last_claimed_at ? (
                                                                <Typography variant="body2">
                                                                    {new Date(chain.last_claimed_at).toLocaleString()}
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Never
                                                                </Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                                                <Tooltip title="View Details">
                                                                    <IconButton
                                                                        component={Link}
                                                                        to={`/distribute-chains/${chain.id}`}
                                                                        size="small"
                                                                        sx={{
                                                                            color: 'primary.main',
                                                                            '&:hover': { backgroundColor: 'primary.lighter' }
                                                                        }}
                                                                    >
                                                                        <SettingsIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit Chain">
                                                                    <IconButton
                                                                        component={Link}
                                                                        to={`/distribute-chains/${chain.id}/edit`}
                                                                        size="small"
                                                                        sx={{
                                                                            color: 'warning.main',
                                                                            '&:hover': { backgroundColor: 'warning.lighter' }
                                                                        }}
                                                                    >
                                                                        <EditIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete Chain">
                                                                    <IconButton
                                                                        onClick={() => handleDelete(chain)}
                                                                        size="small"
                                                                        sx={{
                                                                            color: 'error.main',
                                                                            '&:hover': { backgroundColor: 'error.lighter' }
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={chains.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default DistributeChainComponent;