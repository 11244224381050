import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UpsertCompanyModal from './UpsertCompanyModal';

const UpsertCompany: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    return (
        <UpsertCompanyModal
            open={true}
            onClose={() => navigate('/companies')}
            onSuccess={() => navigate('/companies')}
            companyId={id}
        />
    );
};

export default UpsertCompany;