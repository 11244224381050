import React from 'react';
import { Link } from "react-router-dom";
import { User } from "../../types/User";
import { Typography, Box } from '@mui/material';

interface UserLinkProp {
    user: User|undefined|null
}

const UserLink: React.FC<UserLinkProp> = ({ user }) => {
    if (!user) {
        return <Typography variant="body2" color="text.secondary">None</Typography>;
    }

    return (
        <Box>
            <Link
                to={`/users/${user.id}`}
                style={{
                    textDecoration: 'none',
                    color: 'inherit'
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 500,
                        '&:hover': {
                            color: 'primary.main',
                        }
                    }}
                >
                    {user.username}
                </Typography>
            </Link>
        </Box>
    );
};

export default UserLink;