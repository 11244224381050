import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    LinearProgress,
    Typography,
    Grid,
    Card,
    Box,
    useTheme,
    Chip,
    Stack,
    Divider,
    IconButton,
    Tooltip,
    Paper,
    Fade,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { DistributeChain } from '../../types/DistributeChain';
import { enqueueSnackbar } from "notistack";
import WalletLink from "../Wallet/WalletLink";
import TaskListComponent from "../Tasks/TaskListComponent";
import { TaskLogListComponent } from "../Tasks/TaskLogListComponent";
import CompanyLink from "../Company/CompanyLink";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import UpdateIcon from '@mui/icons-material/Update';
import { useApi } from "../../context/ApiProvider";
import UpsertDistributeChainModal from './UpsertDistributeChainModal';

type RouteParams = {
    [key: number]: string;
};

const DistributeChainOverview: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const api = useApi();
    const { id } = useParams<RouteParams>() as { id: number };
    const [isLoading, setLoading] = useState(true);
    const [distributeChain, setDistributeChain] = useState<DistributeChain | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setLoading(true);
        api?.get(`/distribute-chains/${id}`)
            .then((response) => {
                if (response.ok && response.body) {
                    setDistributeChain(response.body);
                    return;
                }
                if (response.status === 403) {
                    navigate('/distribute-chains');
                }
                throw new Error('Failed to fetch distribute chain data!');
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar('Failed to fetch distribute chain data!', { variant: 'error' });
                setDistributeChain(null);
            })
            .finally(() => setLoading(false));
    }, [id, api, navigate]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this distribute chain?')) {
            api?.delete(`/distribute-chains/${id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete distribute chain');
                    }
                    enqueueSnackbar('Distribute chain deleted successfully!', { variant: 'success' });
                    navigate('/distribute-chains');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete distribute chain!', { variant: 'error' });
                });
        }
    };

    const InfoRow = ({ icon, label, value }: { icon: React.ReactNode, label: string, value: React.ReactNode }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5 }}>
            {icon}
            <Typography variant="body2" color="text.secondary" sx={{ minWidth: 120 }}>
                {label}
            </Typography>
            <Typography variant="body2" color="text.primary" sx={{ flex: 1 }}>
                {value}
            </Typography>
        </Box>
    );

    return (
        <Fade in timeout={800}>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
                            Chain Details
                        </Typography>
                        {distributeChain?.is_active && (
                            <Chip
                                label="Active"
                                color="success"
                                size="small"
                                sx={{ fontWeight: 500 }}
                            />
                        )}
                    </Box>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Edit Chain">
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => setIsModalOpen(true)}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Edit
                            </Button>
                        </Tooltip>
                        <Tooltip title="Delete Chain">
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={handleDelete}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>

                {isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : distributeChain && (
                    <>
                        <Grid item xs={12} md={8}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 3,
                                    boxShadow: theme.shadows[2],
                                    height: '100%',
                                }}
                            >
                                <Box sx={{ p: 3 }}>
                                    <Typography variant="h6" gutterBottom fontWeight={600}>
                                        Chain Information
                                    </Typography>

                                    <Box sx={{ mt: 2 }}>
                                        <InfoRow
                                            icon={<SettingsIcon color="primary" />}
                                            label="Chain Name"
                                            value={distributeChain.name}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<BusinessIcon color="action" />}
                                            label="Company"
                                            value={<CompanyLink company={distributeChain.company} />}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<AccountBalanceWalletIcon color="action" />}
                                            label="Wallet"
                                            value={distributeChain.wallet && <WalletLink wallet={distributeChain.wallet} />}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<CalendarTodayIcon color="action" />}
                                            label="Created At"
                                            value={new Date(distributeChain.created_at!).toLocaleString()}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<UpdateIcon color="action" />}
                                            label="Last Updated"
                                            value={new Date(distributeChain.updated_at!).toLocaleString()}
                                        />
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 3,
                                    boxShadow: theme.shadows[2],
                                    height: '100%',
                                }}
                            >
                                <Box sx={{ p: 3 }}>
                                    <Typography variant="h6" gutterBottom fontWeight={600}>
                                        Chain Configuration
                                    </Typography>

                                    <Stack spacing={2} sx={{ mt: 2 }}>
                                        <Paper
                                            variant="outlined"
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                Chain Details
                                            </Typography>
                                            <Typography variant="body2">
                                                Chain: {distributeChain.chain}
                                            </Typography>
                                            <Typography variant="body2">
                                                Fee: {distributeChain.fee}
                                            </Typography>
                                            <Typography variant="body2">
                                                Gas Adjustment: {distributeChain.gas_adjustment}
                                            </Typography>
                                        </Paper>

                                        <Paper
                                            variant="outlined"
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                Endpoints
                                            </Typography>
                                            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                                REST: {distributeChain.rest_url}
                                            </Typography>
                                            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                                RPC: {distributeChain.rpc_url}
                                            </Typography>
                                        </Paper>

                                        <Paper
                                            variant="outlined"
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                Validator
                                            </Typography>
                                            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                                Address: {distributeChain.validator_address}
                                            </Typography>
                                            <Typography variant="body2">
                                                Claim Greater Than: {distributeChain.claim_greater_than}
                                            </Typography>
                                        </Paper>

                                        {distributeChain.last_error && (
                                            <Paper
                                                variant="outlined"
                                                sx={{
                                                    p: 2,
                                                    borderRadius: 2,
                                                    borderColor: 'error.main',
                                                    bgcolor: 'error.lighter',
                                                }}
                                            >
                                                <Typography variant="subtitle2" color="error" gutterBottom>
                                                    Last Error
                                                </Typography>
                                                <Typography variant="body2" color="error.dark">
                                                    {distributeChain.last_error}
                                                </Typography>
                                            </Paper>
                                        )}
                                    </Stack>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <TaskListComponent tasks={distributeChain.tasks} distributeChain={distributeChain} />
                        </Grid>

                        <Grid item xs={12}>
                            <TaskLogListComponent distributeChain={distributeChain} />
                        </Grid>
                    </>
                )}

                <UpsertDistributeChainModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSuccess={fetchData}
                    chainId={id.toString()}
                />
            </Grid>
        </Fade>
    );
};

export default DistributeChainOverview;