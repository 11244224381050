import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    Typography,
    Grid,
    Card,
    Box,
    useTheme,
    Chip,
    Stack,
    IconButton,
    Tooltip,
    TablePagination,
} from '@mui/material';
import { Task } from "../../types/Task";
import { enqueueSnackbar } from "notistack";
import { TaskLog } from "../../types/TaskLog";
import { DistributeChain } from "../../types/DistributeChain";
import { useApi } from "../../context/ApiProvider";
import HistoryIcon from '@mui/icons-material/History';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import CodeIcon from '@mui/icons-material/Code';
import AssignmentIcon from '@mui/icons-material/Assignment';

interface TaskLogListComponentProps {
    distributeChain?: DistributeChain
    task?: Task
}

export const TaskLogListComponent: React.FC<TaskLogListComponentProps> = ({
                                                                              distributeChain,
                                                                              task
                                                                          }) => {
    const theme = useTheme();
    const api = useApi();
    const [taskLogs, setTaskLogs] = useState<TaskLog[]>([]);
    const [expandedLog, setExpandedLog] = useState<number | null>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const url = task
            ? `/tasks/${task.id}/logs`
            : (distributeChain ? `/distribute-chains/${distributeChain.id}/task-logs` : undefined);

        if (!url) {
            throw new Error('TaskLogListComponent: distributeChain or task must be provided');
        }

        setIsLoading(true);
        api?.get(url)
            .then((response) => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch task logs!');
                }
                setTaskLogs(response.body.results);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch task logs!', { variant: 'error' });
                setTaskLogs([]);
            })
            .finally(() => setIsLoading(false));
    }, [distributeChain, task, api]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const toggleExpand = (logId: number) => {
        setExpandedLog(expandedLog === logId ? null : logId);
    };

    const formatAmount = (amount: string) => {
        const num = parseFloat(amount);
        if (isNaN(num)) return amount;
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6
        }).format(num);
    };

    const getTypeColor = (type: string) => {
        switch (type.toLowerCase()) {
            case 'distribute':
                return 'primary';
            case 'delegate':
                return 'secondary';
            default:
                return 'default';
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h5" sx={{ fontWeight: 600, color: 'text.primary' }}>
                    Task History
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Card
                    elevation={0}
                    sx={{
                        borderRadius: 3,
                        boxShadow: theme.shadows[2],
                        overflow: 'hidden'
                    }}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Task</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Transaction</TableCell>
                                    <TableCell>Timestamp</TableCell>
                                    <TableCell align="right">Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {taskLogs
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((log) => (
                                        <React.Fragment key={log.id}>
                                            <TableRow hover>
                                                <TableCell>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <AssignmentIcon color="primary" />
                                                        <Box>
                                                            <Typography variant="body2" fontWeight={500}>
                                                                {log.task.name}
                                                            </Typography>
                                                            <Typography variant="caption" color="text.secondary">
                                                                ID: {log.task.id}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={log.task.type}
                                                        color={getTypeColor(log.task.type)}
                                                        size="small"
                                                        sx={{
                                                            fontWeight: 500,
                                                            textTransform: 'capitalize'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                                                        {formatAmount(log.amount)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {log.tx_hash ? (
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontFamily: 'monospace',
                                                                    maxWidth: 120,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                            >
                                                                {log.tx_hash}
                                                            </Typography>
                                                            <Tooltip title="View Transaction">
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => window.open(`https://www.mintscan.io/${distributeChain?.chain}/txs/${log.tx_hash}`, '_blank')}
                                                                    sx={{
                                                                        color: 'primary.main',
                                                                        '&:hover': { backgroundColor: 'primary.lighter' }
                                                                    }}
                                                                >
                                                                    <LaunchIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    ) : (
                                                        <Typography variant="body2" color="text.secondary">
                                                            No transaction
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <HistoryIcon fontSize="small" color="action" />
                                                        <Typography variant="body2">
                                                            {new Date(log.created_at).toLocaleString()}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title={expandedLog === log.id ? "Hide Details" : "Show Details"}>
                                                        <IconButton
                                                            onClick={() => toggleExpand(log.id)}
                                                            size="small"
                                                            sx={{
                                                                transition: 'transform 0.2s',
                                                                transform: expandedLog === log.id ? 'rotate(180deg)' : 'none',
                                                                color: 'primary.main',
                                                                '&:hover': { backgroundColor: 'primary.lighter' }
                                                            }}
                                                        >
                                                            <ExpandMoreIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    sx={{
                                                        py: 0,
                                                        borderBottom: expandedLog === log.id ? `1px solid ${theme.palette.divider}` : 'none'
                                                    }}
                                                >
                                                    <Collapse in={expandedLog === log.id} timeout="auto" unmountOnExit>
                                                        <Box sx={{ py: 3, px: 2 }}>
                                                            <Typography variant="subtitle2" gutterBottom color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                <CodeIcon fontSize="small" />
                                                                Extra Data
                                                            </Typography>
                                                            <Paper
                                                                variant="outlined"
                                                                sx={{
                                                                    p: 2,
                                                                    borderRadius: 2,
                                                                    bgcolor: 'grey.50',
                                                                    maxHeight: 400,
                                                                    overflowY: 'auto',
                                                                    overflowX: 'hidden',
                                                                    '& pre': {
                                                                        margin: 0,
                                                                        fontFamily: 'monospace',
                                                                        fontSize: '0.875rem',
                                                                        whiteSpace: 'pre-wrap',
                                                                        wordBreak: 'break-word'
                                                                    }
                                                                }}
                                                            >
                                                                <pre>
                                                                    {JSON.stringify(log.extra_data, null, 2)}
                                                                </pre>
                                                            </Paper>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                {taskLogs.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                                            <Typography variant="body2" color="text.secondary">
                                                No task logs found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={taskLogs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};

export default TaskLogListComponent;