import React, { useCallback, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    useTheme,
    IconButton,
    Typography,
    Box,
    Fade,
    Zoom,
    Paper,
    InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { enqueueSnackbar } from "notistack";
import { WalletInput } from "../../types/Wallet";
import { Company } from "../../types/Company";
import CompanyAutocomplete from "../Company/CompanyAutocomplete";
import { useApi } from "../../context/ApiProvider";

interface UpsertWalletModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    walletId?: string;
}

const UpsertWalletModal: React.FC<UpsertWalletModalProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 onSuccess,
                                                                 walletId
                                                             }) => {
    const theme = useTheme();
    const api = useApi();

    const [name, setName] = useState('');
    const [rawMnemonic, setRawMnemonic] = useState('');
    const [company, setCompany] = useState<Company|null>(null);

    const fetchData = useCallback(() => {
        if (walletId) {
            api?.get(`/wallets/${walletId}`)
                .then(response => {
                    if (!response.ok || !response.body) {
                        throw new Error('Failed to fetch wallet data!');
                    }
                    const data = response.body;
                    setName(data.name);
                    setRawMnemonic('');
                    setCompany(data.company);
                })
                .catch(() => {
                    enqueueSnackbar('Failed to load wallet data!', { variant: 'error' });
                });
        }
    }, [walletId, api]);

    useEffect(() => {
        if (open) {
            if (walletId) {
                fetchData();
            } else {
                // Reset form for new wallet
                setName('');
                setRawMnemonic('');
                setCompany(null);
            }
        }
    }, [open, walletId, fetchData]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!company) {
            enqueueSnackbar('Company is required', { variant: 'error' });
            return;
        }

        if (!walletId && !rawMnemonic) {
            enqueueSnackbar('Mnemonic is required for new wallets', { variant: 'error' });
            return;
        }

        const walletInput: WalletInput = {
            name,
            raw_mnemonic: rawMnemonic,
            company_id: company.id
        };

        try {
            const response = walletId
                ? await api?.put(`/wallets/${walletId}`, walletInput)
                : await api?.post('/wallets', walletInput);

            if (!response?.ok) {
                throw new Error('Failed to process request');
            }

            enqueueSnackbar(
                `Wallet ${walletId ? 'updated' : 'created'} successfully!`,
                { variant: 'success' }
            );
            onSuccess();
            onClose();
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar('Failed to process request!', { variant: 'error' });
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Fade}
            transitionDuration={400}
            PaperProps={{
                elevation: 24,
                sx: {
                    borderRadius: 3,
                    overflow: 'hidden',
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: theme.palette.background.default,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccountBalanceWalletIcon color="primary" />
                        <Typography variant="h6" component="div" fontWeight={600}>
                            {walletId ? 'Update Wallet' : 'Create New Wallet'}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: 'text.secondary',
                            transition: 'all 0.2s',
                            '&:hover': {
                                color: 'text.primary',
                                transform: 'rotate(90deg)',
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Wallet Information
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Zoom in style={{ transitionDelay: '100ms' }}>
                                            <TextField
                                                fullWidth
                                                label="Wallet Name"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                required
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountBalanceWalletIcon color="action" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: 2,
                                                    },
                                                }}
                                            />
                                        </Zoom>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Zoom in style={{ transitionDelay: '200ms' }}>
                                            <TextField
                                                fullWidth
                                                label="Mnemonic"
                                                type="password"
                                                value={rawMnemonic}
                                                onChange={e => setRawMnemonic(e.target.value)}
                                                required={!walletId}
                                                helperText={walletId ? "Leave blank to keep current mnemonic" : "Enter your wallet's mnemonic phrase"}
                                                multiline
                                                rows={2}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: 2,
                                                    },
                                                }}
                                            />
                                        </Zoom>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Company Assignment
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Zoom in style={{ transitionDelay: '300ms' }}>
                                    <Box>
                                        <CompanyAutocomplete
                                            company={company}
                                            setCompany={setCompany}
                                        />
                                    </Box>
                                </Zoom>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        px: 3,
                        py: 2.5,
                        background: theme.palette.background.default,
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            borderColor: theme.palette.divider,
                            color: 'text.secondary',
                            '&:hover': {
                                borderColor: 'text.primary',
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            boxShadow: theme.shadows[2],
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                            },
                        }}
                    >
                        {walletId ? 'Update' : 'Create'} Wallet
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpsertWalletModal;