import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UpsertUserModal from './UpsertUserModal';

const UpsertUser: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    return (
        <UpsertUserModal
            open={true}
            onClose={() => navigate('/users')}
            onSuccess={() => navigate('/users')}
            userId={id}
        />
    );
};

export default UpsertUser;