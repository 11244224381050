import React, {useState} from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Stack,
    Card,
    Box,
    IconButton,
    Tooltip,
    useTheme,
    Chip,
    Grid,
} from '@mui/material';
import { Task } from "../../types/Task";
import { DistributeChain } from "../../types/DistributeChain";
import { enqueueSnackbar } from "notistack";
import { eventEmitter } from "../../services/Events/EventEmitter";
import { EventsEnum } from "../../services/Events/EventsEnum";
import { useApi } from "../../context/ApiProvider";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import UpsertTaskModal from './UpsertTaskModal';

interface TaskListComponentProps {
    distributeChain?: DistributeChain
    tasks: Task[]
}

const TaskListComponent: React.FC<TaskListComponentProps> = ({
                                                                 distributeChain,
                                                                 tasks
                                                             }) => {
    const theme = useTheme();
    const api = useApi();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);

    const handleDelete = (task: Task) => {
        if (window.confirm('Are you sure you want to delete this task?')) {
            api?.delete(`/tasks/${task.id}`)
                .then(() => {
                    enqueueSnackbar('Task deleted successfully!', { variant: 'success' });
                    eventEmitter.emit(EventsEnum.TASK_DELETED);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete task!', { variant: 'error' });
                });
        }
    };

    const handleEdit = (task: Task) => {
        setSelectedTask(task);
        setIsModalOpen(true);
    };

    const handleAdd = () => {
        setSelectedTask(null);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedTask(null);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h5" sx={{ fontWeight: 600, color: 'text.primary' }}>
                    Tasks
                </Typography>
                {distributeChain && (
                    <Button
                        onClick={handleAdd}
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            background: theme.palette.primary.main,
                            '&:hover': {
                                background: theme.palette.primary.dark,
                            }
                        }}
                    >
                        Add Task
                    </Button>
                )}
            </Grid>

            <Grid item xs={12}>
                <Card
                    elevation={0}
                    sx={{
                        borderRadius: 3,
                        boxShadow: theme.shadows[2],
                        overflow: 'hidden'
                    }}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Task</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Wallet</TableCell>
                                    <TableCell>Percentage</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tasks.map((task) => (
                                    <TableRow key={task.id} hover>
                                        <TableCell>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <AssignmentIcon color="primary" />
                                                <Box>
                                                    <Typography variant="body2" fontWeight={500}>
                                                        {task.name}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={task.type}
                                                color="info"
                                                size="small"
                                                sx={{
                                                    fontWeight: 500,
                                                    textTransform: 'capitalize'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                                                {task.wallet_address}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {task.percentage}%
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={task.is_enabled ? 'Active' : 'Inactive'}
                                                color={task.is_enabled ? 'success' : 'default'}
                                                size="small"
                                                sx={{ fontWeight: 500 }}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                                <Tooltip title="Edit Task">
                                                    <IconButton
                                                        onClick={() => handleEdit(task)}
                                                        size="small"
                                                        sx={{
                                                            color: 'warning.main',
                                                            '&:hover': { backgroundColor: 'warning.lighter' }
                                                        }}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Task">
                                                    <IconButton
                                                        onClick={() => handleDelete(task)}
                                                        size="small"
                                                        sx={{
                                                            color: 'error.main',
                                                            '&:hover': { backgroundColor: 'error.lighter' }
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {tasks.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                                            <Typography variant="body2" color="text.secondary">
                                                No tasks found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Grid>

            {distributeChain && (
                <UpsertTaskModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    onSuccess={() => eventEmitter.emit(EventsEnum.TASK_DELETED)}
                    distributeChainId={distributeChain.id}
                    task={selectedTask}
                />
            )}
        </Grid>
    );
};

export default TaskListComponent;