import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    useTheme,
    IconButton,
    Typography,
    Box,
    Fade,
    Paper,
    InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PercentIcon from '@mui/icons-material/Percent';
import { enqueueSnackbar } from "notistack";
import { TaskInput, Task } from "../../types/Task";
import { TaskType } from "../../types/TaskType";
import { useApi } from "../../context/ApiProvider";

interface UpsertTaskModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    distributeChainId: number;
    task?: Task | null;
}

const UpsertTaskModal: React.FC<UpsertTaskModalProps> = ({
                                                             open,
                                                             onClose,
                                                             onSuccess,
                                                             distributeChainId,
                                                             task
                                                         }) => {
    const theme = useTheme();
    const api = useApi();

    const [type, setType] = useState<TaskType>(TaskType.DISTRIBUTE);
    const [name, setName] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [isEnabled, setIsEnabled] = useState(true);

    useEffect(() => {
        if (open) {
            if (task) {
                setType(task.type);
                setName(task.name);
                setWalletAddress(task.wallet_address);
                setPercentage(task.percentage);
                setIsEnabled(task.is_enabled);
            } else {
                // Reset form for new task
                setType(TaskType.DISTRIBUTE);
                setName('');
                setWalletAddress('');
                setPercentage(0);
                setIsEnabled(true);
            }
        }
    }, [open, task]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const taskInput: TaskInput = {
            distribute_chain_id: distributeChainId,
            name,
            type,
            wallet_address: walletAddress,
            percentage,
            is_enabled: isEnabled
        };

        try {
            const response = task
                ? await api?.put(`/tasks/${task.id}`, taskInput)
                : await api?.post('/tasks', taskInput);

            if (!response?.ok) {
                throw new Error('Failed to process request');
            }

            enqueueSnackbar(
                `Task ${task ? 'updated' : 'created'} successfully!`,
                { variant: 'success' }
            );
            onSuccess();
            onClose();
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar('Failed to process request!', { variant: 'error' });
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Fade}
            transitionDuration={400}
            PaperProps={{
                elevation: 24,
                sx: {
                    borderRadius: 3,
                    overflow: 'hidden',
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: theme.palette.background.default,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AssignmentIcon color="primary" />
                        <Typography variant="h6" component="div" fontWeight={600}>
                            {task ? 'Update Task' : 'Create New Task'}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: 'text.secondary',
                            transition: 'all 0.2s',
                            '&:hover': {
                                color: 'text.primary',
                                transform: 'rotate(90deg)',
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Task Information
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Task Type</InputLabel>
                                            <Select
                                                value={type}
                                                label="Task Type"
                                                onChange={e => setType(e.target.value as TaskType)}
                                                required
                                                sx={{
                                                    borderRadius: 2,
                                                }}
                                            >
                                                {Object.values(TaskType).map((value) => (
                                                    <MenuItem key={value} value={value}>
                                                        {value.charAt(0).toUpperCase() + value.slice(1)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Task Name"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AssignmentIcon color="action" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Task Configuration
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Wallet Address"
                                            value={walletAddress}
                                            onChange={e => setWalletAddress(e.target.value)}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountBalanceWalletIcon color="action" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Percentage"
                                            type="number"
                                            value={percentage}
                                            onChange={e => setPercentage(Number(e.target.value))}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PercentIcon color="action" />
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    min: 0,
                                                    max: 100,
                                                }
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={isEnabled}
                                                label="Status"
                                                onChange={e => setIsEnabled(e.target.value === 'true')}
                                                required
                                                sx={{
                                                    borderRadius: 2,
                                                }}
                                            >
                                                <MenuItem value="true">Enabled</MenuItem>
                                                <MenuItem value="false">Disabled</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        px: 3,
                        py: 2.5,
                        background: theme.palette.background.default,
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            borderColor: theme.palette.divider,
                            color: 'text.secondary',
                            '&:hover': {
                                borderColor: 'text.primary',
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            boxShadow: theme.shadows[2],
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                            },
                        }}
                    >
                        {task ? 'Update' : 'Create'} Task
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpsertTaskModal;