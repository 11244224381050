import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UpsertTaskModal from './UpsertTaskModal';

const UpsertTask: React.FC = () => {
    const navigate = useNavigate();
    const { id, task_id } = useParams();

    return (
        <UpsertTaskModal
            open={true}
            onClose={() => navigate(`/distribute-chains/${id}`)}
            onSuccess={() => navigate(`/distribute-chains/${id}`)}
            distributeChainId={Number(id)}
            task={task_id ? { id: Number(task_id) } as any : null}
        />
    );
};

export default UpsertTask;