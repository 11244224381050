import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UpsertWalletModal from './UpsertWalletModal';

const UpsertWallet: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    return (
        <UpsertWalletModal
            open={true}
            onClose={() => navigate('/wallets')}
            onSuccess={() => navigate('/wallets')}
            walletId={id}
        />
    );
};

export default UpsertWallet;