import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Stack,
    useTheme,
    IconButton,
    Typography,
    Box,
    Fade,
    Zoom,
    Paper,
    Grid,
    InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TelegramIcon from '@mui/icons-material/Telegram';
import { enqueueSnackbar } from "notistack";
import { NotificationChannel, NotificationChannelInput } from "../../types/NotificationChannel";
import { NotificationChannelTypeEnum } from "./NotificationChannelType";
import Telegram from "./types/Telegram";
import { TelegramBotConfiguration } from "./types/TelegramBotConfiguration";
import { Company } from "../../types/Company";
import CompanyAutocomplete from "../Company/CompanyAutocomplete";
import { useApi } from "../../context/ApiProvider";

interface UpsertNotificationChannelModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    notificationChannel: NotificationChannel|null;
}

const UpsertNotificationChannelModal: React.FC<UpsertNotificationChannelModalProps> = ({
                                                                                           open,
                                                                                           onClose,
                                                                                           onSuccess,
                                                                                           notificationChannel
                                                                                       }) => {
    const theme = useTheme();
    const api = useApi();

    const [name, setName] = useState('');
    const [type, setType] = useState<NotificationChannelTypeEnum|null>(null);
    const [configurationObject, setConfigurationObject] = useState<any>({});
    const [isEnabled, setIsEnabled] = useState(true);
    const [company, setCompany] = useState<Company|null>(null);

    useEffect(() => {
        if (open) {
            if (notificationChannel) {
                setName(notificationChannel.name);
                setType(notificationChannel.type);
                setConfigurationObject(JSON.parse(notificationChannel.configuration_object));
                setIsEnabled(notificationChannel.is_enabled);
                setCompany(notificationChannel.company);
            } else {
                // Reset form for new channel
                setName('');
                setType(null);
                setConfigurationObject({});
                setIsEnabled(true);
                setCompany(null);
            }
        }
    }, [open, notificationChannel]);

    const handleTest = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!validate()) return;

        try {
            const response = await api?.post('/notification-channels/test', {
                name,
                type: type as NotificationChannelTypeEnum,
                company_id: company?.id ?? 0,
                configuration_object: JSON.stringify(configurationObject ?? {}),
                is_enabled: isEnabled,
            });

            if (!response?.ok) {
                throw new Error('Failed to test notification channel');
            }

            enqueueSnackbar('Test message sent successfully!', { variant: 'success' });
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar('Failed to send test message!', { variant: 'error' });
        }
    };

    const validate = () => {
        if (!name) {
            enqueueSnackbar('Please enter a name!', { variant: 'error' });
            return false;
        }
        if (!type) {
            enqueueSnackbar('Please select a type!', { variant: 'error' });
            return false;
        }
        if (!company) {
            enqueueSnackbar('Please select a company!', { variant: 'error' });
            return false;
        }
        return true;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!validate()) return;

        const channelInput: NotificationChannelInput = {
            name,
            type: type as NotificationChannelTypeEnum,
            company_id: company!.id,
            configuration_object: JSON.stringify(configurationObject ?? {}),
            is_enabled: isEnabled,
            id: notificationChannel?.id,
        };

        try {
            const response = notificationChannel
                ? await api?.put(`/notification-channels/${notificationChannel.id}`, channelInput)
                : await api?.post('/notification-channels', channelInput);

            if (!response?.ok) {
                throw new Error('Failed to process request');
            }

            enqueueSnackbar(
                `Notification channel ${notificationChannel ? 'updated' : 'created'} successfully!`,
                { variant: 'success' }
            );
            onSuccess();
            onClose();
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar('Failed to process request!', { variant: 'error' });
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Fade}
            transitionDuration={400}
            scroll="paper"
            PaperProps={{
                elevation: 24,
                sx: {
                    borderRadius: 3,
                    maxHeight: '90vh',
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: theme.palette.background.default,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <NotificationsIcon color="primary" />
                        <Typography variant="h6" component="div" fontWeight={600}>
                            {notificationChannel ? 'Update Channel' : 'Create New Channel'}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: 'text.secondary',
                            transition: 'all 0.2s',
                            '&:hover': {
                                color: 'text.primary',
                                transform: 'rotate(90deg)',
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Channel Information
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Zoom in style={{ transitionDelay: '100ms' }}>
                                            <TextField
                                                fullWidth
                                                label="Channel Name"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                required
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <NotificationsIcon color="action" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: 2,
                                                    },
                                                }}
                                            />
                                        </Zoom>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Zoom in style={{ transitionDelay: '200ms' }}>
                                            <FormControl fullWidth required>
                                                <InputLabel>Channel Type</InputLabel>
                                                <Select
                                                    value={type ?? ''}
                                                    label="Channel Type"
                                                    onChange={(e: SelectChangeEvent) =>
                                                        setType(e.target.value as NotificationChannelTypeEnum)
                                                    }
                                                    sx={{ borderRadius: 2 }}
                                                >
                                                    {Object.values(NotificationChannelTypeEnum).map((value) => (
                                                        <MenuItem key={value} value={value}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                <TelegramIcon />
                                                                <Typography sx={{ textTransform: 'capitalize' }}>
                                                                    {value}
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Zoom>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Company Assignment
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Zoom in style={{ transitionDelay: '300ms' }}>
                                    <Box>
                                        <CompanyAutocomplete
                                            company={company}
                                            setCompany={setCompany}
                                        />
                                    </Box>
                                </Zoom>
                            </Paper>
                        </Grid>

                        {type === NotificationChannelTypeEnum.TELEGRAM && (
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                    Telegram Configuration
                                </Typography>
                                <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                    <Telegram
                                        configurationObject={configurationObject as TelegramBotConfiguration}
                                        setConfigurationObject={setConfigurationObject}
                                    />
                                </Paper>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Channel Status
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Zoom in style={{ transitionDelay: '400ms' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isEnabled}
                                                onChange={(e) => setIsEnabled(e.target.checked)}
                                                color="success"
                                            />
                                        }
                                        label="Enable Channel"
                                    />
                                </Zoom>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        px: 3,
                        py: 2.5,
                        background: theme.palette.background.default,
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            borderColor: theme.palette.divider,
                            color: 'text.secondary',
                            '&:hover': {
                                borderColor: 'text.primary',
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleTest}
                        variant="outlined"
                        color="warning"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                        }}
                    >
                        Test
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            boxShadow: theme.shadows[2],
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                            },
                        }}
                    >
                        {notificationChannel ? 'Update' : 'Create'} Channel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpsertNotificationChannelModal;