import React from "react";
import { TextField, Box, Zoom } from "@mui/material";
import { TelegramBotConfiguration } from "./TelegramBotConfiguration";

interface TelegramProps {
    configurationObject: TelegramBotConfiguration;
    setConfigurationObject: any;
}

const Telegram: React.FC<TelegramProps> = ({
                                               configurationObject,
                                               setConfigurationObject
                                           }) => {
    const inputStyles = {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            transition: 'all 0.2s',
            '&:hover': {
                backgroundColor: 'action.hover',
            },
            '&.Mui-focused': {
                boxShadow: (theme: any) => `0 0 0 2px ${theme.palette.primary.main}25`,
            },
        },
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Zoom in style={{ transitionDelay: '100ms' }}>
                <TextField
                    label="Bot Token"
                    variant="outlined"
                    helperText="You can get your bot token by sending /newbot to @BotFather"
                    fullWidth
                    required
                    value={configurationObject.bot_token ?? ''}
                    onChange={(event) => {
                        setConfigurationObject({
                            ...configurationObject,
                            bot_token: event.target.value
                        });
                    }}
                    sx={inputStyles}
                />
            </Zoom>

            <Zoom in style={{ transitionDelay: '200ms' }}>
                <TextField
                    label="Chat ID"
                    variant="outlined"
                    helperText="You can get your chat ID by sending /my_id to @get_id_bot"
                    fullWidth
                    required
                    value={configurationObject.chat_id ?? ''}
                    onChange={(event) => {
                        setConfigurationObject({
                            ...configurationObject,
                            chat_id: event.target.value
                        });
                    }}
                    sx={inputStyles}
                />
            </Zoom>

            <Zoom in style={{ transitionDelay: '300ms' }}>
                <TextField
                    label="Topic ID"
                    variant="outlined"
                    helperText="Optional: Topic ID for forum channels"
                    fullWidth
                    value={configurationObject.topic_id ?? ''}
                    onChange={(event) => {
                        setConfigurationObject({
                            ...configurationObject,
                            topic_id: event.target.value
                        });
                    }}
                    sx={inputStyles}
                />
            </Zoom>
        </Box>
    );
};

export default Telegram;