import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    useTheme,
    IconButton,
    Typography,
    Box,
    Fade,
    Zoom,
    Paper,
    InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { enqueueSnackbar } from "notistack";
import { DistributeChainInput } from "../../types/DistributeChain";
import ChainsAutocomplete from "../Chains";
import ValidatorsAutocomplete from "../Validators";
import { ChainDirectory, CosmosDirectory } from "@tedcryptoorg/cosmos-directory";
import { Wallet } from "../../types/Wallet";
import WalletAutocomplete from "../Wallet/WalletAutocomplete";
import { Company } from "../../types/Company";
import CompanyAutocomplete from "../Company/CompanyAutocomplete";
import { useApi } from "../../context/ApiProvider";

interface UpsertDistributeChainModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    chainId?: string;
}

const cosmosDirectory = new CosmosDirectory();
const chainDirectory = new ChainDirectory();

const UpsertDistributeChainModal: React.FC<UpsertDistributeChainModalProps> = ({
                                                                                   open,
                                                                                   onClose,
                                                                                   onSuccess,
                                                                                   chainId
                                                                               }) => {
    const theme = useTheme();
    const api = useApi();

    const [name, setName] = useState('');
    const [chain, setChain] = useState('');
    const [fee, setFee] = useState('');
    const [gasAdjustment, setGasAdjustment] = useState(1.3);
    const [restUrl, setRestUrl] = useState('');
    const [rpcUrl, setRpcUrl] = useState('');
    const [defaultDenom, setDefaultDenom] = useState('');
    const [validatorAddress, setValidatorAddress] = useState('');
    const [claimGreaterThan, setClaimGreaterThan] = useState('');
    const [wallet, setWallet] = useState<Wallet|null>(null);
    const [company, setCompany] = useState<Company|null>(null);
    const [isActive, setIsActive] = useState(true);

    const fetchData = useCallback(() => {
        if (chainId) {
            api?.get(`/distribute-chains/${chainId}`)
                .then((response) => {
                    if (response.ok && response.body) {
                        const data = response.body;
                        setName(data.name);
                        setChain(data.chain);
                        setFee(data.fee);
                        setGasAdjustment(data.gas_adjustment);
                        setRestUrl(data.rest_url);
                        setRpcUrl(data.rpc_url);
                        setDefaultDenom(data.default_denom);
                        setValidatorAddress(data.validator_address);
                        setClaimGreaterThan(data.claim_greater_than);
                        setIsActive(data.is_active);
                        setWallet(data.wallet);
                        setCompany(data.company);
                        return;
                    }
                    throw new Error('Failed to fetch distribute chain data!');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to fetch distribute chain data!', { variant: 'error' });
                });
        }
    }, [chainId, api]);

    useEffect(() => {
        if (open) {
            if (chainId) {
                fetchData();
            } else {
                // Reset form for new chain
                setName('');
                setChain('');
                setFee('');
                setGasAdjustment(1.3);
                setRestUrl('');
                setRpcUrl('');
                setDefaultDenom('');
                setValidatorAddress('');
                setClaimGreaterThan('');
                setWallet(null);
                setCompany(null);
                setIsActive(true);
            }
        }
    }, [open, chainId, fetchData]);

    const handleChainChange = async (selectedChain: string) => {
        setChain(selectedChain);
        const chainData = await chainDirectory.getChainData(selectedChain);
        if (chainData.chain.fees.fee_tokens && chainData.chain.fees.fee_tokens.length > 0) {
            setFee(chainData.chain.fees.fee_tokens[0].average_gas_price.toString());
        }
        setRpcUrl(cosmosDirectory.rpcUrl(selectedChain));
        setRestUrl(cosmosDirectory.restUrl(selectedChain));
        setDefaultDenom(chainData.chain.denom);
        setClaimGreaterThan(Math.pow(10, chainData.chain.decimals).toString());
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!wallet) {
            enqueueSnackbar('Please select a wallet!', { variant: 'error' });
            return;
        }
        if (!company) {
            enqueueSnackbar('Please select a company!', { variant: 'error' });
            return;
        }

        const distributeChain: DistributeChainInput = {
            name,
            chain,
            fee,
            gas_adjustment: gasAdjustment,
            rest_url: restUrl,
            rpc_url: rpcUrl,
            default_denom: defaultDenom,
            validator_address: validatorAddress,
            claim_greater_than: claimGreaterThan,
            wallet_id: wallet.id,
            company_id: company.id,
            is_active: isActive
        };

        try {
            const response = chainId
                ? await api?.put(`/distribute-chains/${chainId}`, distributeChain)
                : await api?.post('/distribute-chains', distributeChain);

            if (!response?.ok) {
                throw new Error('Failed to process request');
            }

            enqueueSnackbar(
                `Chain ${chainId ? 'updated' : 'created'} successfully!`,
                { variant: 'success' }
            );
            onSuccess();
            onClose();
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar('Failed to process request!', { variant: 'error' });
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            TransitionComponent={Fade}
            transitionDuration={400}
            PaperProps={{
                elevation: 24,
                sx: {
                    borderRadius: 3,
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                    m: 2,
                }
            }}
        >
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <DialogTitle
                    sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: theme.palette.background.default,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <SettingsIcon color="primary" />
                        <Typography variant="h6" component="div" fontWeight={600}>
                            {chainId ? 'Update Chain' : 'Create New Chain'}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: 'text.secondary',
                            transition: 'all 0.2s',
                            '&:hover': {
                                color: 'text.primary',
                                transform: 'rotate(90deg)',
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    sx={{
                        p: 3,
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: theme.palette.divider,
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: theme.palette.action.hover,
                        },
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Basic Information
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            required
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ChainsAutocomplete
                                            chain={chain}
                                            setChain={handleChainChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Chain Configuration
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Fee"
                                            value={fee}
                                            onChange={e => setFee(e.target.value)}
                                            required
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Gas Adjustment"
                                            type="number"
                                            value={gasAdjustment}
                                            onChange={e => setGasAdjustment(Number(e.target.value))}
                                            required
                                            inputProps={{
                                                step: 0.1,
                                                min: 0,
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Rest URL"
                                            value={restUrl}
                                            onChange={e => setRestUrl(e.target.value)}
                                            required
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="RPC URL"
                                            value={rpcUrl}
                                            onChange={e => setRpcUrl(e.target.value)}
                                            required
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Default Denom"
                                            value={defaultDenom}
                                            onChange={e => setDefaultDenom(e.target.value)}
                                            required
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ValidatorsAutocomplete
                                            address={validatorAddress}
                                            chain={chain}
                                            setValidatorAddress={setValidatorAddress}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Claim Greater Than"
                                            value={claimGreaterThan}
                                            onChange={e => setClaimGreaterThan(e.target.value)}
                                            required
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Assignments
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <WalletAutocomplete
                                            wallet={wallet}
                                            setWallet={setWallet}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CompanyAutocomplete
                                            company={company}
                                            setCompany={setCompany}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Status
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isActive}
                                            onChange={e => setIsActive(e.target.checked)}
                                            color="success"
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            Active Chain
                                        </Typography>
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        p: 3,
                        background: theme.palette.background.default,
                        borderTop: `1px solid ${theme.palette.divider}`,
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 1,
                        mt: 'auto',
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            borderColor: theme.palette.divider,
                            color: 'text.secondary',
                            '&:hover': {
                                borderColor: 'text.primary',
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            boxShadow: theme.shadows[2],
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                            },
                        }}
                    >
                        {chainId ? 'Update' : 'Create'} Chain
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpsertDistributeChainModal;