import {
    Button,
    Grid,
    LinearProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Card,
    Box,
    IconButton,
    Tooltip,
    useTheme,
    TablePagination,
    Chip,
    Fade
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NotificationChannel } from "../../types/NotificationChannel";
import UpsertNotificationChannelModal from './UpsertNotificationChannelModal';
import { enqueueSnackbar } from "notistack";
import { DistributeChain } from "../../types/DistributeChain";
import CompanyLink from "../Company/CompanyLink";
import { useApi } from "../../context/ApiProvider";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsIcon from '@mui/icons-material/Notifications';

export interface NotificationChannelsListProps {
    distributeChain?: DistributeChain
}

const NotificationChannelsList: React.FC<NotificationChannelsListProps> = ({
                                                                               distributeChain,
                                                                           }) => {
    const theme = useTheme();
    const api = useApi();
    const [notificationChannels, setNotificationChannels] = useState<NotificationChannel[]>([]);
    const [selectedChannel, setSelectedChannel] = useState<NotificationChannel|null>(null);
    const [openModal, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const isFirstRender = useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true);
        setNotificationChannels([]);
        let url = distributeChain
            ? `/distribute-chains/${distributeChain.id}/notification-channels`
            : '/notification-channels';

        api?.get(url)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch notification channels!');
                }
                setNotificationChannels(response.body.results);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch notification channels!', { variant: 'error' });
                setNotificationChannels([]);
            })
            .finally(() => setIsLoading(false));
    }, [api, distributeChain]);

    useEffect(() => {
        if (isFirstRender.current) {
            fetchData();
            isFirstRender.current = false;
        }
    }, [fetchData]);

    const handleModalOpen = () => {
        setSelectedChannel(null);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setSelectedChannel(null);
        setModalOpen(false);
    };

    const handleEdit = (channel: NotificationChannel) => {
        setSelectedChannel(channel);
        setModalOpen(true);
    };

    const handleDelete = (channel: NotificationChannel) => {
        if (window.confirm('Are you sure you want to delete this notification channel?')) {
            api?.delete(`/notification-channels/${channel.id}`)
                .then(() => {
                    enqueueSnackbar('Notification channel deleted successfully!', { variant: 'success' });
                    fetchData();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete notification channel!', { variant: 'error' });
                });
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
                    Notification Channels
                </Typography>
                <Button
                    onClick={handleModalOpen}
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{
                        borderRadius: 2,
                        textTransform: 'none',
                        px: 3,
                        py: 1,
                        background: theme.palette.primary.main,
                        '&:hover': {
                            background: theme.palette.primary.dark,
                        }
                    }}
                >
                    Add Channel
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Card
                    elevation={0}
                    sx={{
                        borderRadius: 3,
                        boxShadow: theme.shadows[2],
                        overflow: 'hidden'
                    }}
                >
                    {isLoading ? (
                        <LinearProgress sx={{ height: 2 }} />
                    ) : (
                        <Box>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Channel</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Company</TableCell>
                                            <TableCell align="right">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {notificationChannels
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((channel) => (
                                                <TableRow key={channel.id} hover>
                                                    <TableCell>
                                                        <Stack direction="row" spacing={2} alignItems="center">
                                                            <NotificationsIcon color="primary" />
                                                            <Typography variant="body2" fontWeight={500}>
                                                                {channel.name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={channel.type}
                                                            color="info"
                                                            size="small"
                                                            sx={{ fontWeight: 500, textTransform: 'capitalize' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={channel.is_enabled ? 'Active' : 'Inactive'}
                                                            color={channel.is_enabled ? 'success' : 'default'}
                                                            size="small"
                                                            sx={{ fontWeight: 500 }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <CompanyLink company={channel.company} />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                                                            <Tooltip title="Edit Channel">
                                                                <IconButton
                                                                    onClick={() => handleEdit(channel)}
                                                                    size="small"
                                                                    sx={{
                                                                        color: 'warning.main',
                                                                        '&:hover': { backgroundColor: 'warning.lighter' }
                                                                    }}
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Channel">
                                                                <IconButton
                                                                    onClick={() => handleDelete(channel)}
                                                                    size="small"
                                                                    sx={{
                                                                        color: 'error.main',
                                                                        '&:hover': { backgroundColor: 'error.lighter' }
                                                                    }}
                                                                >
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={notificationChannels.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                    )}
                </Card>
            </Grid>

            <UpsertNotificationChannelModal
                open={openModal}
                onClose={handleModalClose}
                onSuccess={fetchData}
                notificationChannel={selectedChannel}
            />
        </Grid>
    );
};

export default NotificationChannelsList;