import React from 'react';
import NotificationChannelsList from './NotificationChannelsList';
import { Box, Fade } from '@mui/material';

const NotificationChannelsComponent: React.FC = () => {
    return (
        <Fade in timeout={800}>
            <Box>
                <NotificationChannelsList />
            </Box>
        </Fade>
    );
};

export default NotificationChannelsComponent;