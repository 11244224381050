import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UpsertDistributeChainModal from './UpsertDistributeChainModal';

const UpsertDistributeChain: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    return (
        <UpsertDistributeChainModal
            open={true}
            onClose={() => navigate('/distribute-chains')}
            onSuccess={() => navigate('/distribute-chains')}
            chainId={id}
        />
    );
};

export default UpsertDistributeChain;