import React, { useCallback, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    useTheme,
    IconButton,
    Typography,
    Box,
    Fade,
    Zoom,
    Paper,
    InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { enqueueSnackbar } from "notistack";
import { UserInput } from "../../types/User";
import { Company } from "../../types/Company";
import CompanyAutocomplete from "../Company/CompanyAutocomplete";
import { useApi } from "../../context/ApiProvider";

interface UpsertUserModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    userId?: string;
}

const UpsertUserModal: React.FC<UpsertUserModalProps> = ({
                                                             open,
                                                             onClose,
                                                             onSuccess,
                                                             userId
                                                         }) => {
    const theme = useTheme();
    const api = useApi();

    const [username, setUsername] = useState('');
    const [rawPassword, setRawPassword] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [company, setCompany] = useState<Company|null>(null);

    const fetchData = useCallback(() => {
        if (userId) {
            api?.get(`/users/${userId}`)
                .then(response => {
                    if (!response.ok || !response.body) {
                        throw new Error('Failed to fetch!');
                    }
                    const data = response.body;
                    setUsername(data.username);
                    setIsActive(data.is_active);
                    setIsAdmin(data.is_admin);
                    setIsSuperAdmin(data.is_super_admin);
                    setCompany(data.company);
                })
                .catch(() => {
                    enqueueSnackbar('Failed to load data!', { variant: 'error' });
                });
        }
    }, [userId, api]);

    useEffect(() => {
        if (open) {
            if (userId) {
                fetchData();
            } else {
                // Reset form for new user
                setUsername('');
                setRawPassword('');
                setIsActive(true);
                setIsAdmin(false);
                setIsSuperAdmin(false);
                setCompany(null);
            }
        }
    }, [open, userId, fetchData]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!company) {
            enqueueSnackbar('Please select a company', { variant: 'error' });
            return;
        }
        if (!userId && !rawPassword) {
            enqueueSnackbar('Please enter a password', { variant: 'error' });
            return;
        }

        const userInput: UserInput = {
            username,
            is_active: isActive,
            is_admin: isAdmin,
            is_super_admin: isSuperAdmin,
            company_id: company.id,
            ...(rawPassword && { raw_password: rawPassword })
        };

        const request = userId
            ? api?.put(`/users/${userId}`, userInput)
            : api?.post('/users', userInput);

        if (!request) {
            enqueueSnackbar('Failed to process request!', { variant: 'error' });
            return;
        }

        request
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to process request!');
                }
                enqueueSnackbar(
                    `User ${userId ? 'updated' : 'created'} successfully!`,
                    { variant: 'success' }
                );
                onSuccess();
                onClose();
            })
            .catch((error) => {
                console.error('Error:', error);
                const errorMessage = error.response?.data?.message || error.message || 'Failed to process request!';
                enqueueSnackbar(errorMessage, { variant: 'error' });
            });
    };

    const inputProps = {
        sx: {
            '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                transition: 'all 0.2s',
                '&:hover': {
                    backgroundColor: 'action.hover',
                },
                '&.Mui-focused': {
                    boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
                },
            },
        },
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Fade}
            transitionDuration={400}
            PaperProps={{
                elevation: 24,
                sx: {
                    borderRadius: 3,
                    overflow: 'hidden',
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: theme.palette.background.default,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AdminPanelSettingsIcon color="primary" />
                        <Typography variant="h6" component="div" fontWeight={600}>
                            {userId ? 'Update User' : 'Create New User'}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: 'text.secondary',
                            transition: 'all 0.2s',
                            '&:hover': {
                                color: 'text.primary',
                                transform: 'rotate(90deg)',
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                User Information
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Zoom in style={{ transitionDelay: '100ms' }}>
                                            <TextField
                                                fullWidth
                                                label="Username"
                                                value={username}
                                                onChange={e => setUsername(e.target.value)}
                                                required
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PersonIcon color="action" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...inputProps}
                                            />
                                        </Zoom>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Zoom in style={{ transitionDelay: '200ms' }}>
                                            <TextField
                                                fullWidth
                                                label="Password"
                                                type="password"
                                                value={rawPassword}
                                                onChange={e => setRawPassword(e.target.value)}
                                                required={!userId}
                                                helperText={userId ? "Leave blank to keep current password" : undefined}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockIcon color="action" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...inputProps}
                                            />
                                        </Zoom>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Company Assignment
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Zoom in style={{ transitionDelay: '300ms' }}>
                                    <Box>
                                        <CompanyAutocomplete
                                            company={company}
                                            setCompany={setCompany}
                                        />
                                    </Box>
                                </Zoom>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                User Permissions
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Zoom in style={{ transitionDelay: '400ms' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isActive}
                                                    onChange={e => setIsActive(e.target.checked)}
                                                    color="success"
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    Active User Account
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isAdmin}
                                                    onChange={e => setIsAdmin(e.target.checked)}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    Admin Privileges
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isSuperAdmin}
                                                    onChange={e => setIsSuperAdmin(e.target.checked)}
                                                    color="secondary"
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    Super Admin Privileges
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                </Zoom>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        px: 3,
                        py: 2.5,
                        background: theme.palette.background.default,
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            borderColor: theme.palette.divider,
                            color: 'text.secondary',
                            '&:hover': {
                                borderColor: 'text.primary',
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            boxShadow: theme.shadows[2],
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                            },
                        }}
                    >
                        {userId ? 'Update' : 'Create'} User
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpsertUserModal;