import React from 'react';
import { Link } from "react-router-dom";
import { Wallet } from "../../types/Wallet";
import { Typography, Box } from '@mui/material';

interface WalletLinkProps {
    wallet: Wallet
}

const WalletLink: React.FC<WalletLinkProps> = ({ wallet }) => {
    return (
        <Box>
            <Link
                to={`/wallets/${wallet.id}`}
                style={{
                    textDecoration: 'none',
                    color: 'inherit'
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 500,
                        '&:hover': {
                            color: 'primary.main',
                        }
                    }}
                >
                    {wallet.name}
                </Typography>
            </Link>
        </Box>
    );
};

export default WalletLink;