import React, { useState, useEffect, useCallback } from 'react';
import {
    Grid,
    Card,
    Typography,
    Box,
    useTheme,
    LinearProgress,
    Stack,
    Chip,
    Fade,
    Paper,
} from '@mui/material';
import { useApi } from "../context/ApiProvider";
import { enqueueSnackbar } from "notistack";
import { DistributeChain } from "../types/DistributeChain";
import { TaskLog } from "../types/TaskLog";
import { Wallet } from "../types/Wallet";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {formatTokenAmount} from "../utils";

const DashboardComponent: React.FC = () => {
    const theme = useTheme();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [chains, setChains] = useState<DistributeChain[]>([]);
    const [recentLogs, setRecentLogs] = useState<TaskLog[]>([]);
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true);
        Promise.all([
            api?.get('/distribute-chains'),
            api?.get('/task-logs?limit=20'),
            api?.get('/wallets')
        ])
            .then(([chainsResponse, logsResponse, walletsResponse]) => {
                if (chainsResponse?.ok) {
                    setChains(chainsResponse.body.results || []);
                }
                if (logsResponse?.ok) {
                    setRecentLogs(logsResponse.body.results || []);
                }
                if (walletsResponse?.ok) {
                    setWallets(walletsResponse.body.results || []);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch dashboard data!', { variant: 'error' });
            })
            .finally(() => setIsLoading(false));
    }, [api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const StatCard = ({ title, value, icon, color }: { title: string, value: string | number, icon: React.ReactNode, color: string }) => (
        <Card
            elevation={0}
            sx={{
                borderRadius: 3,
                boxShadow: theme.shadows[2],
                height: '100%',
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="text.secondary">
                    {title}
                </Typography>
                <Typography variant="h4" sx={{ mt: 1, fontWeight: 600 }}>
                    {value}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color,
                }}
            >
                {icon}
            </Box>
        </Card>
    );

    if (isLoading) {
        return <LinearProgress />;
    }

    const activeChains = chains.filter(chain => chain.is_active).length;
    const chainsWithErrors = chains.filter(chain => chain.last_error).length;
    const totalWallets = wallets.length;
    const activeTasks = chains.reduce((sum, chain) => sum + chain.tasks.filter(task => task.is_enabled).length, 0);

    return (
        <Fade in timeout={800}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary', mb: 3 }}>
                        Dashboard
                    </Typography>
                </Grid>

                {/* Stats Overview */}
                <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                        title="Active Chains"
                        value={activeChains}
                        icon={<SettingsIcon fontSize="large" />}
                        color={theme.palette.primary.main}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                        title="Active Tasks"
                        value={activeTasks}
                        icon={<AssignmentIcon fontSize="large" />}
                        color={theme.palette.secondary.main}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                        title="Total Wallets"
                        value={totalWallets}
                        icon={<AccountBalanceWalletIcon fontSize="large" />}
                        color={theme.palette.success.main}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                        title="Chains with Errors"
                        value={chainsWithErrors}
                        icon={<NotificationsIcon fontSize="large" />}
                        color={theme.palette.error.main}
                    />
                </Grid>

                {/* Chain Status */}
                <Grid item xs={12} md={6}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 3,
                            boxShadow: theme.shadows[2],
                            height: '100%',
                            p: 3,
                        }}
                    >
                        <Typography variant="h6" gutterBottom fontWeight={600}>
                            Chain Status
                        </Typography>
                        <Stack spacing={2}>
                            {chains.map((chain) => (
                                <Paper
                                    key={chain.id}
                                    variant="outlined"
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        {chain.last_error ? (
                                            <ErrorIcon color="error" />
                                        ) : (
                                            <CheckCircleIcon color="success" />
                                        )}
                                        <Box>
                                            <Typography variant="subtitle2">
                                                {chain.name}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                {chain.chain}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    <Chip
                                        label={chain.last_error ? 'Error' : 'Active'}
                                        color={chain.last_error ? 'error' : 'success'}
                                        size="small"
                                    />
                                </Paper>
                            ))}
                        </Stack>
                    </Card>
                </Grid>

                {/* Recent Activity */}
                <Grid item xs={12} md={6}>
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: 3,
                            boxShadow: theme.shadows[2],
                            height: '100%',
                            p: 3,
                        }}
                    >
                        <Typography variant="h6" gutterBottom fontWeight={600}>
                            Recent Activity
                        </Typography>
                        <Stack spacing={2}>
                            {recentLogs.map((log) => (
                                <Paper
                                    key={log.id}
                                    variant="outlined"
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                    }}
                                >
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <AssignmentIcon color="primary" />
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Typography variant="subtitle2">
                                                {log.task.name}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                                                Amount: {formatTokenAmount({denom: log.task.distribute_chain.default_denom, amount: log.amount})}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ textAlign: 'right' }}>
                                            <Chip
                                                label={log.task.type}
                                                color="primary"
                                                size="small"
                                                sx={{ mb: 1 }}
                                            />
                                            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                                                {new Date(log.created_at).toLocaleString()}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Paper>
                            ))}
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default DashboardComponent;