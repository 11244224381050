import React from 'react';
import GraphIcon from '@mui/icons-material/GraphicEq';
import SettingsIcon from '@mui/icons-material/Settings';
import WalletIcon from '@mui/icons-material/Wallet';
import NotificationChannelIcon from '@mui/icons-material/Message';
import LogoutIcon from '@mui/icons-material/Logout';
import CompanyIcon from '@mui/icons-material/House';
import UserIcon from '@mui/icons-material/Person';
import { User } from "./types/User";

interface NavItem {
  name: string;
  icon: React.ComponentType;
  path: string;
  security: (user: User) => boolean;
}

export const navbarItems: NavItem[] = [
  {
    name: 'Dashboard',
    icon: GraphIcon,
    path: '/',
    security: () => true
  },
  {
    name: 'Distribute chains',
    icon: SettingsIcon,
    path: '/distribute-chains',
    security: () => true
  },
  {
    name: 'Wallets',
    icon: WalletIcon,
    path: '/wallets',
    security: (user: User) => user.is_super_admin
  },
  {
    name: 'Notification channels',
    icon: NotificationChannelIcon,
    path: '/notification-channels',
    security: (user: User) => user.is_super_admin
  },
  {
    name: 'Users',
    icon: UserIcon,
    path: '/users',
    security: (user: User) => user.is_super_admin
  },
  {
    name: 'Companies',
    icon: CompanyIcon,
    path: '/companies',
    security: (user: User) => user.is_super_admin
  },
  {
    name: 'Logout',
    icon: LogoutIcon,
    path: '/logout',
    security: () => true
  },
];