import React from 'react';
import { Link } from "react-router-dom";
import { Company } from "../../types/Company";
import { Typography, Box } from '@mui/material';

interface CompanyLinkProp {
    company: Company|undefined|null
}

const CompanyLink: React.FC<CompanyLinkProp> = ({ company }) => {
    if (!company) {
        return <Typography variant="body2" color="text.secondary">None</Typography>;
    }

    return (
        <Box>
            <Link
                to={`/companies/${company.id}`}
                style={{
                    textDecoration: 'none',
                    color: 'inherit'
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 500,
                        '&:hover': {
                            color: 'primary.main',
                        }
                    }}
                >
                    {company.name}
                </Typography>
            </Link>
        </Box>
    );
};

export default CompanyLink;