import React, { useCallback, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    useTheme,
    IconButton,
    Typography,
    Box,
    Fade,
    Zoom,
    Paper,
    InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import { enqueueSnackbar } from "notistack";
import { CompanyInput } from "../../types/Company";
import { useApi } from "../../context/ApiProvider";

interface UpsertCompanyModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    companyId?: string;
}

const UpsertCompanyModal: React.FC<UpsertCompanyModalProps> = ({
                                                                   open,
                                                                   onClose,
                                                                   onSuccess,
                                                                   companyId
                                                               }) => {
    const theme = useTheme();
    const api = useApi();

    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(true);

    const fetchData = useCallback(() => {
        if (companyId) {
            api?.get(`/companies/${companyId}`)
                .then(response => {
                    if (!response.ok || !response.body) {
                        throw new Error('Failed to fetch!');
                    }
                    const data = response.body;
                    setName(data.name);
                    setIsActive(data.is_active);
                })
                .catch(() => {
                    enqueueSnackbar('Failed to load data!', { variant: 'error' });
                });
        }
    }, [companyId, api]);

    useEffect(() => {
        if (open) {
            if (companyId) {
                fetchData();
            } else {
                // Reset form for new company
                setName('');
                setIsActive(true);
            }
        }
    }, [open, companyId, fetchData]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const companyInput: CompanyInput = {
            name,
            is_active: isActive,
        };

        const request = companyId
            ? api?.put(`/companies/${companyId}`, companyInput)
            : api?.post('/companies', companyInput);

        if (!request) {
            enqueueSnackbar('Failed to process request!', { variant: 'error' });
            return;
        }

        request
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to process request!');
                }
                enqueueSnackbar(
                    `Company ${companyId ? 'updated' : 'created'} successfully!`,
                    { variant: 'success' }
                );
                onSuccess();
                onClose();
            })
            .catch((error) => {
                console.error('Error:', error);
                const errorMessage = error.response?.data?.message || error.message || 'Failed to process request!';
                enqueueSnackbar(errorMessage, { variant: 'error' });
            });
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Fade}
            transitionDuration={400}
            PaperProps={{
                elevation: 24,
                sx: {
                    borderRadius: 3,
                    overflow: 'hidden',
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: theme.palette.background.default,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BusinessIcon color="primary" />
                        <Typography variant="h6" component="div" fontWeight={600}>
                            {companyId ? 'Update Company' : 'Create New Company'}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: 'text.secondary',
                            transition: 'all 0.2s',
                            '&:hover': {
                                color: 'text.primary',
                                transform: 'rotate(90deg)',
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Company Information
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Zoom in style={{ transitionDelay: '100ms' }}>
                                            <TextField
                                                fullWidth
                                                label="Company Name"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                required
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <BusinessIcon color="action" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: 2,
                                                    },
                                                }}
                                            />
                                        </Zoom>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Company Status
                            </Typography>
                            <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                <Zoom in style={{ transitionDelay: '200ms' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isActive}
                                                onChange={e => setIsActive(e.target.checked)}
                                                color="success"
                                            />
                                        }
                                        label={
                                            <Typography variant="body2">
                                                Active Company
                                            </Typography>
                                        }
                                    />
                                </Zoom>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        px: 3,
                        py: 2.5,
                        background: theme.palette.background.default,
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            borderColor: theme.palette.divider,
                            color: 'text.secondary',
                            '&:hover': {
                                borderColor: 'text.primary',
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            minWidth: 100,
                            boxShadow: theme.shadows[2],
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                            },
                        }}
                    >
                        {companyId ? 'Update' : 'Create'} Company
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpsertCompanyModal;