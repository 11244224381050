import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    LinearProgress,
    Typography,
    Grid,
    Card,
    Box,
    useTheme,
    Stack,
    Divider,
    IconButton,
    Tooltip,
    Paper,
    Fade,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from "notistack";
import { Wallet } from "../../types/Wallet";
import ChainsAutocomplete from "../Chains";
import { useApi } from "../../context/ApiProvider";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import UpdateIcon from '@mui/icons-material/Update';
import CompanyLink from '../Company/CompanyLink';

type RouteParams = {
    [key: number]: string;
};

const WalletOverview: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const api = useApi();
    const { id } = useParams<RouteParams>() as { id: number };
    const [isLoading, setLoading] = useState(true);
    const [wallet, setWallet] = useState<Wallet | null>(null);
    const [generatedAddress, setGeneratedAddress] = useState<string | null>(null);
    const [selectedChain, setSelectedChain] = useState<string | undefined>(undefined);
    const [lastGeneratedChain, setLastGeneratedChain] = useState<string | undefined>(undefined);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setLoading(true);
        api?.get(`/wallets/${id}`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to fetch wallet data!');
                }
                setWallet(response.body);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch wallet data!', { variant: 'error' });
                setWallet(null);
            })
            .finally(() => setLoading(false));
    }, [id, api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this wallet?')) {
            api?.delete(`/wallets/${id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete wallet');
                    }
                    enqueueSnackbar('Wallet deleted successfully!', { variant: 'success' });
                    navigate('/wallets');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    enqueueSnackbar('Failed to delete wallet!', { variant: 'error' });
                });
        }
    };

    const generateChain = (chain: string) => {
        api?.get(`/wallets/${id}/address/${chain}`)
            .then(response => {
                if (!response.ok || !response.body) {
                    throw new Error('Failed to generate address!');
                }
                setGeneratedAddress(response.body.address);
                setLastGeneratedChain(chain);
                setSelectedChain(undefined); // Reset the chain input
                enqueueSnackbar(`Address generated for ${chain}`, { variant: 'success' });
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to generate address!', { variant: 'error' });
                setGeneratedAddress(null);
                setLastGeneratedChain(undefined);
                setSelectedChain(undefined);
            });
    };

    const InfoRow = ({ icon, label, value }: { icon: React.ReactNode, label: string, value: React.ReactNode }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5 }}>
            {icon}
            <Typography variant="body2" color="text.secondary" sx={{ minWidth: 120 }}>
                {label}
            </Typography>
            <Typography variant="body2" color="text.primary" sx={{ flex: 1 }}>
                {value}
            </Typography>
        </Box>
    );

    return (
        <Fade in timeout={800}>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
                            Wallet Details
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Edit Wallet">
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => navigate(`/wallets/${id}/edit`)}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Edit
                            </Button>
                        </Tooltip>
                        <Tooltip title="Delete Wallet">
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={handleDelete}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                    </Stack>
                </Grid>

                {isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : wallet && (
                    <>
                        <Grid item xs={12} md={8}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 3,
                                    boxShadow: theme.shadows[2],
                                    height: '100%',
                                }}
                            >
                                <Box sx={{ p: 3 }}>
                                    <Typography variant="h6" gutterBottom fontWeight={600}>
                                        Wallet Information
                                    </Typography>

                                    <Box sx={{ mt: 2 }}>
                                        <InfoRow
                                            icon={<AccountBalanceWalletIcon color="primary" />}
                                            label="Wallet Name"
                                            value={wallet.name}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<BusinessIcon color="action" />}
                                            label="Company"
                                            value={<CompanyLink company={wallet.company} />}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<CalendarTodayIcon color="action" />}
                                            label="Created At"
                                            value={new Date(wallet.created_at!).toLocaleString()}
                                        />
                                        <Divider />
                                        <InfoRow
                                            icon={<UpdateIcon color="action" />}
                                            label="Last Updated"
                                            value={new Date(wallet.updated_at!).toLocaleString()}
                                        />
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 3,
                                    boxShadow: theme.shadows[2],
                                    height: '100%',
                                }}
                            >
                                <Box sx={{ p: 3 }}>
                                    <Typography variant="h6" gutterBottom fontWeight={600}>
                                        Generate Address
                                    </Typography>

                                    <Box sx={{ mt: 2 }}>
                                        <ChainsAutocomplete chain={selectedChain} setChain={generateChain} />

                                        {generatedAddress && (
                                            <Paper
                                                variant="outlined"
                                                sx={{
                                                    mt: 2,
                                                    p: 2,
                                                    borderRadius: 2,
                                                    borderColor: 'primary.main',
                                                    bgcolor: 'primary.lighter',
                                                }}
                                            >
                                                <Typography variant="subtitle2" color="primary.main" gutterBottom>
                                                    Generated Address for {lastGeneratedChain}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        wordBreak: 'break-all',
                                                        fontFamily: 'monospace',
                                                        color: 'primary.dark',
                                                    }}
                                                >
                                                    {generatedAddress}
                                                </Typography>
                                            </Paper>
                                        )}
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    </>
                )}
            </Grid>
        </Fade>
    );
};

export default WalletOverview;