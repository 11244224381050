import React, { useState } from 'react';
import { Button, Box, Paper } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useApi } from "../../context/ApiProvider";
import { useUserStore } from "../../stores/useUserStore";
import { useNavigate } from "react-router-dom";
import LoginHeader from './LoginHeader';
import LoginForm from './LoginForm';

const LoginComponent: React.FC = () => {
    const api = useApi();
    const { handleLoginResponse } = useUserStore();
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const response = await api?.post('/login', {
                username: username,
                password: password
            });

            handleLoginResponse(response);
            navigate('/');
        } catch (error: any) {
            console.error('Error:', error);
            enqueueSnackbar(
                error.response?.data?.message || 'Failed to log in!',
                { variant: 'error' }
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'linear-gradient(120deg, #1a237e 0%, #0d47a1 100%)',
            }}
        >
            <Paper
                elevation={24}
                sx={{
                    p: 4,
                    width: '100%',
                    maxWidth: 400,
                    mx: 2,
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.98)',
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleLogin}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <LoginHeader />

                    <LoginForm
                        username={username}
                        password={password}
                        showPassword={showPassword}
                        onUsernameChange={(e) => setUsername(e.target.value)}
                        onPasswordChange={(e) => setPassword(e.target.value)}
                        onTogglePassword={() => setShowPassword(!showPassword)}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        size="large"
                        disabled={isLoading}
                        sx={{
                            mt: 2,
                            py: 1.5,
                            textTransform: 'none',
                            fontSize: '1.1rem',
                            fontWeight: 600,
                            boxShadow: 4,
                            '&:hover': {
                                boxShadow: 6,
                            },
                        }}
                    >
                        {isLoading ? 'Signing in...' : 'Sign In'}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default LoginComponent;